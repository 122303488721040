import * as React from "react";
import ContactUsContainer from "../containers/contact-us";
import DefaultLayout from "../layouts/default";
export default () => {

  return (
    <>
      <DefaultLayout animatedHeader title="Contact Us">
        <ContactUsContainer />
      </DefaultLayout>
    </>
  );
};

import { Link } from "gatsby";
import React, { ReactElement } from "react";
import Heading from "../../components/heading";
import ContactForm from "../../hoc/contact-form";
import FilledButton from "../../hoc/filled-button";
import "./index.module.css";

export interface ContactUsContainerProps { }


export default function ContactUsContainer(
  props: ContactUsContainerProps
): ReactElement | null {

  return (
    <>
      <div id="faqs" className="mb-16 px-8 md:px-0">
        <div className="text-center my-8">
          <Heading position="center">
            <h2>Contact Us</h2>
          </Heading>
        </div>

        <div className="w-full md:w-1/2 mx-auto">
          <ContactForm />
        </div>
        <div className="w-full md:w-2/3 mx-auto p-4 bg-white shadow-md rounded-md my-8">
          <p>
            Lending-Ground is one of the USA’s leading installment loan introducers; we work closely with the top US lenders to provide a loan option for you. To contact your loan provider please refer to your loan agreement.
          </p>
          <p>
            As we are an online only service we do not operate a call center, however if you have any questions with regards to our website or the service we provide please do not hesitate to contact our friendly customer service team by emailing: customers@lending-ground.com
          </p>
          <p>
            We aim to respond to all inquiries within 2 hours of receipt. Please check our frequently asked questions to see if the answer you seek is a common question we have listed.
          </p>
          <p>
            Lending-Ground is a trading name of Lending Ground Inc.
          </p>
          Email: customers@lending-ground.com
        </div>
        <Link to="/apply" className="mt-8 flex justify-center">
          <FilledButton>
            REQUEST FUNDS NOW
          </FilledButton>
        </Link>
      </div>
    </>
  )
}

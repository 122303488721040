import React, { ReactElement } from "react"
import Button from "../../components/button"
import Input from "../../components/input"
import Utils from "../../utils"
import FilledButton from "../filled-button"

interface Props { }

function ContactForm({ }: Props): ReactElement {
  const [formData, setFormData] = React.useState({ referer: 'SFK_CH_NEW:contact' } as any)
  const [error, setError] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [done, setDone] = React.useState(false)
  const onChange = (field: string) => (value: any) => {
    const _d = { ...formData }
    _d[field] = value
    setFormData(_d)
  }

  const validate = () => {
    if (!formData || Object.keys(formData).length <= 0) {
      setError("Please fill all required fields!")
      return false
    }

    if (!formData.firstName || formData.firstName.length < 1) {
      setError("Please provide your full name!")
      return false
    }
    if (!formData.phone || formData.phone.length < 6) {
      setError("Please provide correct phone number (min 6 chars)!")
      return false
    }

    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi
    if (
      !formData.email ||
      formData.email.length <= 0 ||
      !emailRegex.test(formData.email)
    ) {
      setError("Please provide correct email-id!")
      return false
    }

    return true
  }

  const submitForm = async () => {
    setLoading(true)
    try {
      const validated = validate()
      if (validated) {
        // const nData = { entity: formData, email: formData.email }
        const { data } = await Utils.API.post("eusers/register", formData)
        setDone(true)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      {/* {JSON.stringify(formData)} */}
      {done ? (
        <>
          <div className="bg-white rounded shadow-lg p-4">
            Thankyou for contacting us. One of our agents will get in touch with you within next 24 hours!
          </div>
        </>
      ) : (
        <div className="px-4">
          {error && error.length ? (
            <>
              <div className="p-4 bg-yellow-400 rounded">{error}</div>
            </>
          ) : (
            ""
          )}
          <div className="py-2">
            <label>
              <h6>Name</h6>
              <Input
                onChange={onChange("firstName")}
                value={formData["firstName"]}
                className="w-full shadow-none"
                wrapperClass="shadow-md rounded-md"
                type="text"
              />
            </label>
          </div>
          <div className="py-2">
            <label>
              <h6>Email</h6>
              <Input
                onChange={onChange("email")}
                value={formData["email"]}
                className="w-full shadow-none"
                wrapperClass="shadow-md rounded-md"
                type="text"
              />
            </label>
          </div>
          <div className="py-2">
            <label>
              <h6>Phone</h6>
              <Input
                onChange={onChange("phone")}
                value={formData["phone"]}
                className="w-full shadow-none"
                wrapperClass="shadow-md rounded-md"
                type="text"
              />
            </label>
          </div>
          <div className="py-2">
            <label>
              <h6>Message</h6>
              <textarea
                onChange={e => onChange("message")(e.target.value)}
                value={formData["message"]}
                className="shadow-md w-full rounded-lg p-4"
              />
            </label>
          </div>
          <div className="py-2">
            <FilledButton
              onClick={submitForm}
              className="w-full justify-center"
            >
              {loading ? "Please Wait..." : "Submit"}
            </FilledButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default ContactForm
